<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    class="d-flex justify-center align-center"
  >
   <h1 class="blue--text">Bem Vindo ao CMS!!!</h1>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
    }},

    methods: {

    },
  }
</script>
